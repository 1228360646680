import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/styles';

export const styles = (theme: Theme): StyleRules => ({
  headerClose: {
    height: '60px',
    padding:'0 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor:'#000000',
  },
  closeImg: {
    width: '20px',
    height: '20px',
    cursor: 'pointer',
  },
  logo:{
    maxHeight: '42px',
    maxWidth:'300px',
    [theme.breakpoints.down('sm')]: {
      maxWidth:'150px'
    },
  }
});
