import { makeStyles, Theme } from '@material-ui/core';

export const useSocialStyles = makeStyles<Theme>(theme => ({
  drawerLink: {
    padding: '20px 0',
    '& ul': {
      padding: 0,
      margin: 0,
      justifyContent: 'flex-start',
    },
    '& li': {
      padding: 0,
      marginRight: '15px',
      '& svg': {
        width: '26px',
        height: '26px',
      },
    },

  },
  list: {
    display: 'flex',
    margin: theme.spacing(1),
    padding: theme.spacing(1, 2),
    listStyle: 'none',
    height: '28px',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '4px',
    justifyContent: 'center',
  },
  item: {
    padding: theme.spacing(0, 1),
    display: 'flex',
    alignItems: 'center',
  },

  icon: {
    padding: 4,
  },
}));
