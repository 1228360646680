import { makeStyles, Theme } from '@material-ui/core';

export const useSocialStyles = makeStyles<Theme>(theme => ({
  drawerLink: {
    padding: '20px 0',
    '& ul': {
      padding: 0,
    },
    '& li': {
      padding: 0,
      marginRight: '15px',
      '& svg': {
        width: '26px',
        height: '26px',
      },
    },

  },
  list: {
    display: 'flex',
    margin: 0,
    padding: theme.spacing(1, 2),
    listStyle: 'none',
    height: '28px',
    borderRadius: '4px',
  },

  item: {
    padding: theme.spacing(0, 1),
    display: 'flex',
    alignItems: 'center',
  },

  icon: {
    padding: 0,
    '& svg': {
      width: '16px',
      height: '16px',
    },
  },
}));
