import React, { ChangeEvent, useCallback,useRef } from 'react';
import { Locale } from 'modules/i18n/types/locale';
import { setLocale } from 'modules/i18n/i18nSlice';
import { useLocaleMemo } from 'modules/i18n/utils/useLocaleMemo';
import { useLocale } from 'modules/i18n/utils/useLocale';
import { styles } from './LocaleSwitcherStyles';
import { Select } from 'modules/uiKit/Select';
import { t } from 'modules/i18n/utils/intl';
import { withStyles } from "@material-ui/core";
import { useIsSMDown } from 'modules/themes/useTheme';
import { Box } from '@material-ui/core';
import { useAppDispatch } from 'store/useAppDispatch';
import { FocusOn } from 'react-focus-on';
import classNames from 'classnames';
import { useWalletDropdown } from '../../../layout/components/Wallet/useWalletDropdown';
import HeaderLang from 'modules/common/components/Icons/headerL.svg';

const LocaleSwitcherComponent = (
  { classes = {} }: { classes: any }
) => {
  const dispatch = useAppDispatch();
  const localeOptions = useLocaleMemo(
    () => [
      {
        value: Locale.en,
        label: t('language.en-US'),
      },
      {
        value: Locale.ja,
        label: t('language.ja-JP'),
      },
      {
        value: Locale.zh,
        label: t('language.zh-CN'),
      }
    ],
    [],
  );

  const { locale } = useLocale();
  const isMobile = useIsSMDown();
  const onChange = useCallback(
    (event: ChangeEvent<{ value: unknown }>) => {
      dispatch(setLocale(event.target.value as Locale));
    },
    [dispatch],
  );
  const controlRef = useRef<HTMLButtonElement>(null);

  const {
    isOpened,
    handleClose,
    handleOpen,
  } = useWalletDropdown();

  const onHandleChange = (value: string) => {
    dispatch(setLocale(value as Locale));
  };

  return (
    isMobile?<Box>
        <Box onClick={handleOpen} className={classes.imgRoot}>
          <img src={HeaderLang} alt='' className={classes.userIcon} />
        </Box>
        <FocusOn
          enabled={isOpened}
          onEscapeKey={handleClose}
          onClickOutside={handleClose}
          focusLock={true}
          scrollLock={false}
          shards={[controlRef]}
        >
          <div
            className={classNames(
              classes.dropdown,
              isOpened && classes.dropdownActive,
            )}
          >
            <div className={classes.absolute}>
              {
                localeOptions.map(d => {
                  return (
                    <Box onClick={() => onHandleChange(d.value)} key={d.value}
                      className={classNames(classes.language, locale === d.value ? classes.active : '')}
                    >{d.label}
                    </Box>
                  );
                })
              }
            </div>
          </div>
        </FocusOn>
      </Box>
      :<Select
      className={classes.root}
      value={locale}
      onChange={onChange}
      options={localeOptions}
      fullWidth={false}
      classes={{ select: classes.slectedStyle }}
    />
  );
};

const LocaleSwitcher = withStyles(styles)(LocaleSwitcherComponent);

export { LocaleSwitcher };
