
import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const Telegram = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 20 20">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 10C0 4.47581 4.47581 0 10 0C15.5242 0 20 4.47581 20 10C20 15.5242 15.5242 20 10 20C4.47581 20 0 15.5242 0 10ZM13.2702 14.5847L14.9113 6.85084C15.0565 6.17342 14.6653 5.90326 14.2178 6.06455L4.57663 9.78229C3.91534 10.0404 3.92743 10.4073 4.46372 10.5766L6.93147 11.3468L12.6573 7.74197C12.9234 7.56455 13.1694 7.66536 12.9678 7.84278L8.33469 12.0283L8.15727 14.5726C8.41534 14.5726 8.52824 14.4597 8.6613 14.3266L9.86695 13.1654L12.3669 15.0081C12.8226 15.2662 13.1492 15.1331 13.2702 14.5847Z" fill="white" />
        </SvgIcon>
    );
};