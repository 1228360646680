import { Container } from '@material-ui/core';
import { useFooterStyles } from './FooterStyles';
import { Social } from './components/Social';
import { Typography } from '@material-ui/core';
import { getDfdunkLogo, IDfdunkLogoData } from 'modules/common/actions/commonRequest';
import { getDfdunkSns, IDfdunkSnsData } from 'modules/common/actions/commonRequest';
import { useQuery } from '@redux-requests/react';

export const Footer = () => {
  const classes = useFooterStyles();
  const { data: logoData } = useQuery<IDfdunkLogoData | null>({
    type: getDfdunkLogo.toString(),
  });
  const { data: snsData } = useQuery<IDfdunkSnsData | null>({
    type: getDfdunkSns.toString(),
  });

  return (
    <footer className={classes.root}>
      <Container maxWidth={false} className={classes.container}>
        <Social
          twitter={snsData?.twitter}
          instagram={snsData?.instagram}
          telegram={snsData?.telegram}
          discord={snsData?.discord}
          facebook={snsData?.facebook}
        />
        <div className={classes.copyright}>
          <img src={logoData ? logoData.logo : ''} alt="" />
        </div>
        <div className={classes.terms}>
          <Typography>TERMS OF USE</Typography>
          <Typography>COOKIE PREFERENCES</Typography>
          <Typography>PRIVACY POLICY</Typography>
        </div>
        <Typography className={classes.copy}>© 2022 ACTRESS ALL RIGHTS RESERVED</Typography>
      </Container>
    </footer>
  );
};
