import { Theme } from '@material-ui/core';
import { StyleRules } from '@material-ui/styles';

export const styles = (theme: Theme): StyleRules => ({
  root: {
    width: '30px',
    height: '30px',
    // border: `1px solid ${theme.palette.grey[50]}`,
    display: 'none',
    cursor: 'pointer',
    marginLeft: '10px',
    textAlign: 'center',
    [theme.breakpoints.up('xs')]: {
      width: '20px',
      height: '20px',
      display: 'block',
    },
    [theme.breakpoints.up('sm')]: {
      width: '20px',
      height: '20px',
      display: 'block',
    },
    [theme.breakpoints.up('md')]: {
      width: '20px',
      height: '20px',
      display: 'block',
    },
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  userIcon: {
    width: '15px',
    height: '17px',
    margin: 'auto',
    [theme.breakpoints.up('xs')]: {
      width: '11px',
      height: '11px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '11px',
      height: '11px',
    },
    [theme.breakpoints.up('md')]: {
      width: '17px',
      height: '17px',
    },
  },
  paper: {
    width: '100%',
  },
  drawerBody: {
    padding: '0',
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.7)',
    backdropFilter: 'blur(10px)',
    '& a:hover': {
      color: '#F34063',
    },
  },
  drawerContent: {
    padding: '0 20px',
  },
  link: {
    color: '#000000',
    fontWeight: 700,
    fontSize: '22px',
    borderBottom: '1.4px solid rgba(255, 255, 255, 0.2)',
    borderRadius: 0,
    padding: 0,
    marginTop: '15px',
    '& span': {
      justifyContent: 'start',
    },
  },
  linkWrap: {
    display: 'flex',
    flexDirection: 'column',
  },
  activeLink: {
    color: '#F34063',
  },
});
