import { makeStyles, Theme } from '@material-ui/core';

export const useHeaderLinksStyles = makeStyles<Theme>(theme => ({
  root: {
    display: 'grid',

    [theme.breakpoints.up('xl')]: {
      justifyContent: 'center',
      gridAutoFlow: 'column',
      gap: theme.spacing(0, 4),
    },
  },

  link: {
    transition: 'color 0.2s',
    fontSize: 12,
    justifyContent: 'flex-start',
    minWidth: 0,
    padding:'7 15px',
    lineHeight: 'inherit',
    color: '#fff',
    cursor: 'pointer',
    background: 'rgba(255, 255, 255, 0.1)',
    backdropFilter: 'blur(24px)',
    borderRadius: '4px',
    height: '28px',
    fontWeight: 400,
    '&:hover': {
        background: 'rgba(255, 255, 255, 0.3)',
        color: '#fff'
    },
    [theme.breakpoints.up('xs')]: {
      display:'none'
    },
    [theme.breakpoints.up('sm')]: {
      display:'none'
    },
    [theme.breakpoints.up('md')]: {
      display:'none'
    },
    [theme.breakpoints.up('lg')]: {
      display:'block'
    },
    [theme.breakpoints.up('xl')]: {
      display:'block'
    },
    [theme.breakpoints.up('HD')]: {
      display:'block'
    },
    [theme.breakpoints.up('WXGAPlus')]: {
      display:'block'
    },
  },
  activeLink: {
    cursor: 'default',
    background: 'rgba(255, 255, 255, 0.3)'
  },
}));
