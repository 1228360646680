import React from 'react';

import DarkCloseImg from '../../../../../../assets/img/header/darkClose.png';
import { Box } from '@material-ui/core';
import { styles } from './useDraweCloseStyle';
import { withStyles } from '@material-ui/core';
// import { Logo } from 'modules/layout/components/Logo';
import { getDfdunkLogo, IDfdunkLogoData} from 'modules/common/actions/commonRequest';
import { useQuery } from '@redux-requests/react';

interface DraweCloseInterface {
  classes: any;
  toggleDrawer: (e: any) => void
}

const DraweClose = (
  {
    classes = {},
    toggleDrawer,
  }: DraweCloseInterface) => {

  const { data: logoData } = useQuery<IDfdunkLogoData | null>({
    type: getDfdunkLogo.toString(),
  });
  return (
    <Box className={classes.headerClose}>
      <img src={logoData?logoData.logo:''} className={classes.logo} alt="" />
      <img src={DarkCloseImg} onClick={toggleDrawer} alt='' className={classes.closeImg} />
    </Box>
  );
};
export const DraweCloseComp = withStyles(styles)(DraweClose);
