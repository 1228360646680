import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const CopyIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path
        d="M7 4.75H14C14.6904 4.75 15.25 5.30964 15.25 6V13H16.75V6C16.75 4.48122 15.5188 3.25 14 3.25H7V4.75ZM5.5 6.25C4.25736 6.25 3.25 7.25736 3.25 8.5V14.5C3.25 15.7426 4.25736 16.75 5.5 16.75H11.5C12.7426 16.75 13.75 15.7426 13.75 14.5V8.5C13.75 7.25736 12.7426 6.25 11.5 6.25H5.5ZM4.75 8.5C4.75 8.08579 5.08579 7.75 5.5 7.75H11.5C11.9142 7.75 12.25 8.08579 12.25 8.5V14.5C12.25 14.9142 11.9142 15.25 11.5 15.25H5.5C5.08579 15.25 4.75 14.9142 4.75 14.5V8.5Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
