import loadable, { LoadableComponent } from '@loadable/component';
import { QueryLoadingAbsolute } from 'modules/common/components/QueryLoading/QueryLoading';
import { RouteConfiguration } from 'modules/common/types/RouteConfiguration';
import { Route } from 'react-router-dom';

const PATH_MINARO_COLLECTION = '/actress/collection';

export const MinaroCollectionRoutesConfig: { [key: string]: RouteConfiguration } = {
  Index: {
    path: PATH_MINARO_COLLECTION,
    generatePath: () => PATH_MINARO_COLLECTION,
  },
};

const LoadableIndexContainer: LoadableComponent<any> = loadable(
  async () =>
    import('./screens/Collection').then(module => module.Collection),
  {
    fallback: <QueryLoadingAbsolute />,
  },
);

export function MinaroCollectionRoutes() {
  return (
    <Route
      path={MinaroCollectionRoutesConfig.Index.path}
      exact={true}
      component={LoadableIndexContainer}
    />
  );
}
