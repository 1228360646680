import { fade, makeStyles, Theme } from '@material-ui/core';


export const HEADER_HEIGHT_XS = 66;
export const HEADER_HEIGHT_XL = 66;

export const useHeaderStyles = makeStyles<Theme>(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.25, 0),
    // borderBottom: `1px solid ${fade(theme.palette.text.primary, 0.15)}`,
    background: '#222',
    color: theme.palette.text.primary,
    height: HEADER_HEIGHT_XS,

    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(2, 0),
      height: HEADER_HEIGHT_XL,
    },
  },
  overViewHead: {
    zIndex: 2,
    background: fade(theme.palette.background.default, 0.1),
    [theme.breakpoints.down('sm')]: {
      background: theme.palette.background.default,
    },
  },

  container: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0, 2),
    gridTemplateColumns: 'auto auto',
    justifyContent: 'space-between',
    [theme.breakpoints.up('xl')]: {
      // gridTemplateColumns: 'auto auto 1fr repeat(2, auto)',
      // justifyContent: 'initial',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },

    [theme.breakpoints.up('WXGAPlus')]: {
      paddingLeft: theme.spacing(5.5),
      paddingRight: theme.spacing(5.5),
    },
  },

  search: {
    [theme.breakpoints.up('xl')]: {
      width: 340,
    },
  },

  searchMobile: {
    Width: '100%',
  },

  searchBox: {
    position: 'absolute',
    zIndex: 10,
    top: 0,
    left: 0,

    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',

    background: theme.palette.background.default,
  },

  searchBoxContainer: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr auto',
    gap: theme.spacing(0, 2),
  },

  btnCreate: {
    [theme.breakpoints.up('xl')]: {
      marginLeft: theme.spacing(2),
    },
  },

  buttons: {
    display: 'grid',
    alignItems: 'center',
    gap: theme.spacing(0, 2),
    gridTemplateColumns: 'auto auto',
  },

  drawer: {
    top: `${HEADER_HEIGHT_XS}px !important`,
  },

  drawerPaper: {
    position: 'absolute',
    width: '100%',
    background: theme.palette.common.white,
    color: theme.palette.common.black,
    maxWidth: 410,
  },

  drawerBackdrop: {
    position: 'absolute',
  },

  navInner: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },

  btnChangeNet: {
    marginRight: 18,
    height: '48px',
    fontSize: '16px',
    color: '#fff',
  },
  nav: {
    display: 'flex',
    justifyContent: 'end'
  },
  connect: {
    height: '28px',
    fontSize: '12px',
    color: '#fff',
    padding: '8px 16px',
    fontWeight: 400,
    minWidth: '78px',
    marginLeft: '8px',
  },
  connectBorder: {
    background: 'rgba(255, 255, 255, 0.1)',
    backdropFilter: 'blur(24px)',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: fade('#8468FF', 0.8),
    },
  },
  connectMobile: {
    border: 'none',
    backgroundColor: 'transparent',
    backgroundRepeat: 'no-repeat',
    height: '48px',
    width: '200px',
    color: '#fff'
  },
  headerImg: {
    position: 'absolute',
    top: '20px',
    right: '0',
    width: '40%'
  },
  img: {
    maxHeight: '42px',
    maxWidth:'300px',
    [theme.breakpoints.down('sm')]: {
      maxWidth:'150px'
    },
  },
  loading: {
    animationName: '$spin',
    animationDuration: '1s',
    animationDelay: '0s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    willChange: 'transform',
    marginLeft: '10px',
  },
  '@keyframes spin': {
    '100%': { transform: 'rotate(360deg)' },
  },
  pending: {
    marginLeft: '6px'
  },
  navs: {
    display: 'flex'
  },
  links: {
    marginRight: '20px'
  },
  newsearchBox: {
    width: '250px',
    height: '40px',
    borderRadius: '20px',
    border: '1px solid #EAECEF',
    position: 'relative',
    overflow: 'hidden'
  },
  hearderSearch: {
    height: '38px',
    fontSize: '16px',
    position: 'absolute',
    right: 0,
    top: 0,
    width: '200px',
    border: 'none',
    outline: 'none'
  },
  searchIcon: {
    position: 'absolute',
    top: '8px',
    left: '20px',
  },
  switcher: {
    background: 'rgba(255, 255, 255, 0.1)',
    borderRadius: '4px',
    marginLeft: '8px',
    [theme.breakpoints.up('xs')]: {
      display:'none'
    },
    [theme.breakpoints.up('sm')]: {
      display:'none'
    },
    [theme.breakpoints.up('md')]: {
      display:'none'
    },
    [theme.breakpoints.up('lg')]: {
      display:'block'
    },
    [theme.breakpoints.up('xl')]: {
      display:'block'
    },
    [theme.breakpoints.up('HD')]: {
      display:'block'
    },
    [theme.breakpoints.up('WXGAPlus')]: {
      display:'block'
    },
  },
  switcherLocal:{
    
  }
}));
