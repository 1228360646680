import { Route, Switch, Redirect } from 'react-router-dom';
import { DefaultLayout } from './modules/layout/components/DefautLayout';
import { PageNotFound } from './modules/router/components/PageNotFound';
import { Themes } from './modules/themes/types';
import { IndexRoutes, IndexRoutesConfig } from './modules/minarioHome/Routes';
import { MinaroMintRoutes, MinaroMintRoutesConfig } from './modules/minarioMint/Routes';
import { MinaroCollectionRoutes, MinaroCollectionRoutesConfig } from './modules/minarioCollection/Routes';
import { PageComingSoon } from './modules/router/components/PageComingSoon';

const projectId = process.env.REACT_APP_BASE_ENV_BRANCH;
export function Routes() {
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => <Redirect to={IndexRoutesConfig.Index.path} />}
      />

      {projectId === 'ACTRESS' && <Route
        exact
        path={IndexRoutesConfig.Index.path}
        render={() => (
          <DefaultLayout headerTheme={Themes.light}>
            <IndexRoutes />
          </DefaultLayout>
        )}
      />
      }
      {projectId === 'ACTRESS' && <Route
        exact
        path={MinaroMintRoutesConfig.Index.path}
        render={() => (
          <DefaultLayout headerTheme={Themes.light}>
            <MinaroMintRoutes />
          </DefaultLayout>
        )}
      />
      }
      <Route
        exact
        path={'/actress/coming_soon'}
        render={() => (
          <DefaultLayout headerTheme={Themes.light}>
            <PageComingSoon />
          </DefaultLayout>
        )}
      />
      {projectId === 'ACTRESS' && <Route
        exact
        path={MinaroCollectionRoutesConfig.Index.path}
        render={() => (
          <DefaultLayout headerTheme={Themes.light}>
            <MinaroCollectionRoutes />
          </DefaultLayout>
        )}
      />
      }
      <Route
        render={() => (
          <DefaultLayout>
            <PageNotFound />
          </DefaultLayout>
        )}
      />
    </Switch>
  );
}
