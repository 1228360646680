import { fade, Theme } from '@material-ui/core';
import { StyleRules } from "@material-ui/styles";

export const styles = (theme: Theme): StyleRules => ({
  root: {
    fontSize: 14,
    fontWeight: 400,
    minWidth: 60,
    maxWidth: 80,
    height: 28,
    color: '#fff',
    // background: 'rgba(255, 255, 255, 0.1)',
    marginLeft: '8px',
    borderRadius: '4px',
    '& svg': {
      width: '20px',
      height: "10px",
      color: '#fff',
      top: 'calc(50% - 5px)',
      right: '8px',
    },
    [theme.breakpoints.down('md')]: {
      minWidth: 52,
      marginLeft: '0',
    },
  },
  slectedStyle: {
    padding: '8px 24px 8px 14px !important',
    [theme.breakpoints.down('md')]: {
      padding: '8px 24px 8px 8px !important',
    },
  },
  imgRoot:{
     width: '30px',
    height: '30px',
    border: `1px solid ${theme.palette.grey[50]}`,
    display: 'flex',
    cursor: 'pointer',
    marginLeft:'10px',
    [theme.breakpoints.up('xs')]: {
      width: '24px',
      height: '24px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '24px',
      height: '24px',
    },
    [theme.breakpoints.up('md')]: {
      width: '30px',
      height: '30px',
    },
  },
  userIcon: {
    width: '17px',
    height: '17px',
    margin: 'auto',
    [theme.breakpoints.up('xs')]: {
      width: '16px',
      height: '16px',
    },
    [theme.breakpoints.up('sm')]: {
      width: '16px',
      height: '16px',
    },
    [theme.breakpoints.up('md')]: {
      width: '17px',
      height: '17px',
    },
  },
  absolute: {
    display: 'flex',
    flexDirection: 'column',
  },
  language: {
    width: '100%',
    height: '34px',
    fontSize: '14px',
    padding: '0 20px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    color: theme.palette.common.white,
    '&:hover':{
      background: fade(theme.palette.common.white,.1),
    }
  },
  link: {
    justifyContent: 'start',
    padding: ' 0 20px',
    borderRadius: 0,
    '&.active': {
      background: fade(theme.palette.common.white,.1),
      color: '#ffffff',
      borderRadius: 0,
    },
  },
  disconnect: {
    height: '44px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 20px',
    cursor: 'pointer',
    fontWeight: 500,
    color: theme.palette.common.white,
    fontSize: '14px',
  },
  active: {
    background: fade(theme.palette.common.white,.1),
    color: '#ffffff',
  },

  dropdown: {
    width: '80px',
    position: 'absolute',
    zIndex: theme.zIndex.tooltip,
    top: '100%',//`calc(100% - ${theme.spacing(1)}px)`,
    right: theme.spacing(3),

    pointerEvents: 'none',
    opacity: 0,
    transform: `translateY(${theme.spacing(-1)}px)`,
    transition: 'opacity 0.2s, transform 0.2s',
    // borderRadius: '12px',

    backgroundColor: '#000',
    padding: theme.spacing(1, 0, 1),
    border: `1px solid ${fade(theme.palette.grey[50], 1)}`,
  },

  dropdownActive: {
    opacity: 1,
    transform: 'translateY(0)',
    pointerEvents: 'auto',
  },
});
