import loadable, { LoadableComponent } from '@loadable/component';
import { QueryLoadingAbsolute } from 'modules/common/components/QueryLoading/QueryLoading';
import { RouteConfiguration } from 'modules/common/types/RouteConfiguration';
import { Route } from 'react-router-dom';

const PATH_MINARO_MINT = '/actress/mint';

export const MinaroMintRoutesConfig: { [key: string]: RouteConfiguration } = {
  Index: {
    path: PATH_MINARO_MINT,
    generatePath: () => PATH_MINARO_MINT,
  },
};

const LoadableIndexContainer: LoadableComponent<any> = loadable(
  async () =>
    import('./screens/Mint').then(module => module.Mint),
  {
    fallback: <QueryLoadingAbsolute />,
  },
);

export function MinaroMintRoutes() {
  return (
    <Route
      path={MinaroMintRoutesConfig.Index.path}
      exact={true}
      component={LoadableIndexContainer}
    />
  );
}
