
import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

export const Facebook = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 20 20">
            <path fillRule="evenodd" clipRule="evenodd" d="M1.12625 0.40625H17.6863C18.0845 0.40625 18.4062 0.728 18.4062 1.12625V17.6863C18.4062 18.0845 18.0845 18.4062 17.6863 18.4062H12.8239V11.4335H15.1639L15.5149 8.71777H12.8239V6.98302C12.8239 6.19552 13.0422 5.66002 14.1694 5.66002H15.6072H15.6094V3.23227C15.3597 3.19852 14.5069 3.12427 13.5124 3.12427C11.4379 3.12427 10.0182 4.39102 10.0182 6.71527V8.71777H7.67144V11.4358H10.0182V18.4062H1.12625C0.728 18.4062 0.40625 18.0845 0.40625 17.6863V1.12625C0.40625 0.728 0.728 0.40625 1.12625 0.40625Z" fill="white" />
        </SvgIcon>
    );
};